/**
 * axios实例封装，在这里加上项目对接口请求的统一处理
 */
import axios from 'axios'
import config from './config'
import store from '@/store'
import Router from '@/router'
import message from 'ant-design-vue/es/message'
import Modal from 'ant-design-vue/es/modal'

const { apiHost } = config

const option = {
  baseURL: apiHost,
  timeout: 20000, // 请求超时时间
}

// 创建 axios 实例Authorization:
  const service = axios.create(option)
    service.interceptors.request.use(
      config => {
        // console.log(store.state.Public.Token)
        // config.headers.Authorization = 'Bearer b181f627-9294-4745-90fd-25db8b448a9a'
        config.headers.Authorization =  store.state.Public.Token === '' ? 'Bearer '+ null : 'Bearer ' + store.state.Public.Token
        return config
      },
      error => {
        return Promise.reject(error)
      }
    )
    service.interceptors.response.use(
      response => {
        const res = response.data
        // 判断处理结果是文件类型时，不对结果集处理，直接返回
        if (res instanceof Blob) {
          return response
        }
        if (!res.success) {
          if(res.code === '401'){
            store.state.Public.Token =''
            Modal.confirm({
              title: "提示",
              content: "身份已失效，请进行以下操作。",
              okText: "登录",
              cancelText: "首页",
              onOk() {
                Router.push('/login')
                // CheckRoute();
              },
              onCancel() {
                store.state.Public.Num='100'
                Router.push('/')
              },
            })
          }else{
            message.error((res && res.errorMsg) || '未知异常！')
          }
          // return Promise.reject(new Error((res && res.errorMsg) || '未知异常！'))
          return res
        } else {
          return res
        }
      },
      error => {
        // if (error?.response?.status === 401) {
        //     sessionStorage.clear()
        //     location.href='http://192.168.233.181/login'
        // }
        if (axios.isCancel(error)) {
          return null
        }
        return Promise.reject(error)
      }
    )


export default service
